import { create } from 'apisauce'
import {
  api,
  Asn,
  Asns,
  AsnStatus,
  ParcelState,
  Places,
  ResourcePage,
  responseErrorCheck,
  ShippingParcel,
  ShippingParcelCreateRequest,
  ShippingParcelEntry,
  ShippingParcelUpdateRequest,
  TmrBaseResource,
  TmrPlace,
  ShippingTag,
} from 'stylewhere/api'
import { AppStore, OperationConfig, ShippingOperationConfig } from 'stylewhere/shared'
import { PackingOperationConfig } from 'stylewhere/shared/RemoteOperation'

export interface ParcelDetailResponse {
  parcel: ShippingParcel
  details: { parcelEntry: ShippingParcelEntry; statuses: ParcelState[] }[]
}
export class Shippings extends TmrBaseResource {
  static endpoint = 'api/v1/operations' //logistics

  static getOperationEndpoint(operation: ShippingOperationConfig) {
    if (operation.type === 'INBOUND') return `${this.endpoint}/inbound`
    if (operation.type === 'OUTBOUND') return `${this.endpoint}/outbound`
    if (operation.type === 'PACKING') return `${this.endpoint}/packing`
    return ''
  }

  static async getAsnExtended(asnId: string) {
    const result = await api.get<Asn>(`${Asns.endpoint}/${asnId}`)
    const success = responseErrorCheck(result)

    let destinationPlace: TmrPlace = { id: '', code: '' }
    if (success.destinationPlaceId) {
      const place = await Places.get<TmrPlace>(success.destinationPlaceId)
      destinationPlace = place
    }
    return {
      ...success,
      destinationPlace,
    }
  }

  // eslint-disable-next-line consistent-return
  static batchValidateEndpoint(operation: ShippingOperationConfig) {
    return `${this.getOperationEndpoint(operation)}/batchValidate`
  }

  static checkParcelData(operation: ShippingOperationConfig, data: ShippingParcelCreateRequest, withAsn = true) {
    if (withAsn && operation.type === 'INBOUND' && !data.asn?.destinationPlaceId) {
      data.asn = {
        ...data.asn,
        destinationPlaceId: AppStore.defaultWorkstation?.placeId,
      } as any
    } else if (withAsn && operation.type === 'OUTBOUND' && !data.asn?.originPlaceId) {
      data.asn = {
        ...data.asn,
        originPlaceId: AppStore.defaultWorkstation?.placeId,
      } as any
    } else if (operation.type === 'PACKING' && !data.operationPlaceId) {
      data.operationPlaceId = AppStore.defaultWorkstation!.placeId
    }
    return data
  } 

  static startParcel(operation: ShippingOperationConfig, data: ShippingParcelCreateRequest) {
    const parcelData = Shippings.checkParcelData(operation, data, true)
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/startParcel`, {
        ...parcelData,
        operationId: operation.id,
      })
      .then(responseErrorCheck)      
  }

  static updateParcel(operation: ShippingOperationConfig, data: ShippingParcelUpdateRequest) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/updateParcelContent`, {
        ...data,
        operationId: operation.id,
      })
      .then(responseErrorCheck)
  }

  static confirmParcel(
    operation: ShippingOperationConfig,
    data: ShippingParcelCreateRequest,
    force = false,
    async = false,
    withAsn = false
  ) {
    let path = ''
    if (force) path = '/force'
    else if (async) path = '/asyncExecution'

    const parcelData = Shippings.checkParcelData(operation, data, withAsn)
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmParcel${path}`, {
        ...parcelData,
        operationId: operation.id,
      })
      .then(responseErrorCheck)
  }

  static parcelByIdentifier(operation: ShippingOperationConfig, identifiersArray: ShippingTag | ShippingTag[]) {
    const identifiers = Array.isArray(identifiersArray) ? identifiersArray : [identifiersArray]

    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/parcelByIdentifier`, {
        operationId: operation.id,
        identifiers: identifiers,
        identifierCode: identifiers[0].code,
      })
      .then(responseErrorCheck)
  }

  static parcelByChecklist(operation: ShippingOperationConfig, checklistId: string, size = 100000) {
    return api
      .get(`${this.getOperationEndpoint(operation)}/parcelVersionList`, {
        operationId: operation.id,
        checklistId,
        size,
      })
      .then(responseErrorCheck)
  }

  static startChecklist(operation: OperationConfig, data: string, fieldName: string) {
    return api
      .post<any>(`${this.endpoint}/packing/${operation.id}/startChecklist`, {
        [fieldName]: data,
      })
      .then(responseErrorCheck)
  }

  static closeParcel(operation: ShippingOperationConfig, checklistId: string) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmChecklist`, { checklistId })
      .then(responseErrorCheck)
  }

  static getAsnList(
    operation: ShippingOperationConfig,
    pageSize: number,
    pageIndex: number,
    textFilter?: string,
    statuses?: AsnStatus[],
    order: 'asc' | 'desc' = 'desc'
  ) {
    const places: { [placeID: string]: TmrPlace } = {}
    return api
      .get<ResourcePage<Asn>>(`${this.getOperationEndpoint(operation)}/asn/list`, {
        operationId: operation.id,
        destinationPlaceId: AppStore.defaultWorkstation?.placeId,
        size: pageSize,
        page: pageIndex,
        likeCodes: textFilter,
        statuses: statuses,
        sort: `creationDate,${order}`,
      })
      .then(responseErrorCheck)
      .then(async (resource) => {
        // FIXME - temporary until STL5-1734 implemented
        const contentPromised = await Promise.all(
          resource.content.map(async (asn) => {
            let destinationPlace: TmrPlace = { id: '', code: '' }
            if (asn.destinationPlaceId) {
              if (places[asn.destinationPlaceId]) {
                destinationPlace = places[asn.destinationPlaceId]
              } else {
                const place = await Places.get<TmrPlace>(asn.destinationPlaceId)
                destinationPlace = places[asn.destinationPlaceId] = place
              }
            }
            return {
              ...asn,
              destinationPlace,
            }
          })
        )
        return {
          ...resource,
          content: contentPromised,
        }
      })
  }

  static startAsn(operation: ShippingOperationConfig, asnCode: string, attributes?: Record<string, any>) {
    return api
      .post<Asn>(`${this.getOperationEndpoint(operation)}/startAsn`, {
        operationId: operation.id,
        asnCode,
        destinationPlaceId: AppStore.defaultWorkstation?.placeId,
        attributes: attributes ?? {},
      })
      .then(responseErrorCheck)
  }

  static getParcelListByAsn(
    operation: ShippingOperationConfig,
    asnCode: string,
    pageSize: number,
    pageIndex: number,
    search?: string,
    order: 'asc' | 'desc' = 'desc'
  ) {
    return api
      .get<ResourcePage<ShippingParcel>>(`${this.getOperationEndpoint(operation)}/parcelList`, {
        operationId: operation.id,
        size: pageSize,
        page: pageIndex,
        ascCode: asnCode, // FIXME typo in backend
        search,
        sort: `creationDate,${order}`,
      })
      .then(responseErrorCheck)
  }

  static getParcelDetail(operation: ShippingOperationConfig, parcelCode: string) {
    return api
      .get<ParcelDetailResponse>(`${this.getOperationEndpoint(operation)}/parcelDetail`, {
        operationId: operation.id,
        parcelCode,
      })
      .then(responseErrorCheck)
  }

  static confirmAsn(operation: ShippingOperationConfig, asnCode: string, attributes: any) {
    return api
      .post(`${this.getOperationEndpoint(operation)}/confirmAsn`, {
        operationId: operation.id,
        asnCode,
        attributes,
      })
      .then(responseErrorCheck)
  }

  static unpackPacking(operation: PackingOperationConfig, parcelCode: string) {
    return api
      .post(`${this.getOperationEndpoint(operation)}/unpack`, {
        operationId: operation.id,
        parcelCode,
        clientPlaceId: AppStore.defaultWorkstation?.placeId,
      })
      .then(responseErrorCheck)
  }

  static print(parcelCode: string, pickingListAttributes: any, endpoint: string) {
    const dataToPrint =
      '' +
      '^XA' +
      '^FWR' +
      '^MD00' +
      '^PR2' +
      '^LH0048,0024' +
      '^BY4^FS' +
      '^FO1050,0070^AG,120,80^FD' +
      pickingListAttributes.CountryShipTo +
      '^FS' +
      '^FO1000,0070^AG,60,40^FD' +
      pickingListAttributes.Season +
      ' ' +
      pickingListAttributes.DocumentOrderInvoice +
      ' ' +
      pickingListAttributes.MarketingLine +
      '^FS' +
      '^FO0840,0070^AG,95,65^FD' +
      pickingListAttributes.operation_id +
      '^FS' +
      '^FO0840,001800^AG,300,205^FD' +
      pickingListAttributes.items_quantity +
      '^FS' +
      '^FO0810,0070^AC,36,20^FDBC             ^FS' +
      '^FO0670,0070^AG,60,40^FDCARTON NUMBER^FS' +
      '^FO0350,0070^AG,95,65^FD' +
      (parcelCode.startsWith('999') ? parcelCode.substr(3) : parcelCode) +
      '^FS' +
      '^FO0520,0070^BC,140,N,N,N^FD' +
      (parcelCode.startsWith('999') ? parcelCode.substr(3) : parcelCode) +
      '^FS' +
      '^FO0670,1200^AG,60,40^FDTMR WHS^FS' +
      '^FO0350,1200^AG,95,65^FD' +
      parcelCode +
      '^FS' +
      '^FO0520,1200^BC,140,N,N,N^FD' +
      parcelCode +
      '^FS' +
      '^FO0250,0070^AG,60,40^FD' +
      pickingListAttributes.NameAlpha +
      '^FS' +
      '^FO0150,0070^AG,60,40^FD' +
      pickingListAttributes.MailingName +
      '^FS' +
      '^FO0050,0070^AG,60,40^FD' +
      pickingListAttributes.AddressNumberShipTo +
      ' - ' +
      pickingListAttributes.CityStateShipTo +
      '^FS' +
      '^XZ'
    const printerEndpoint = create({
      baseURL: 'http://' + endpoint,
      timeout: 3000,
    })
    printerEndpoint.setHeaders({
      Accept: 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    return printerEndpoint.post(`pstprnt`, dataToPrint).then(responseErrorCheck)
  }
}
